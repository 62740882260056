import React, { useState } from "react";
import { Wheel } from "react-custom-roulette";
import "./SpinWheel.css"; // Import your custom CSS for additional styling
import { Link } from "react-router-dom";

const SpinWheel = () => {
  const [mustSpin, setMustSpin] = useState(false);
  const [prizeNumber, setPrizeNumber] = useState();
  const [prize, setPrize] = useState(localStorage.getItem("prize"));

  const data = [
    {
      option: "Better luck next time",
      style: {
        backgroundColor: "#EE4040",
        textColor: "white",
        padding: "20px",
      },
    },
    {
      option: "Won 70",
      style: {
        backgroundColor: "#F0CF50",
        textColor: "black",
        padding: "20px",
      },
    },
    {
      option: "Won 10",
      style: {
        backgroundColor: "#815CD1",
        textColor: "white",
        padding: "20px",
      },
    },
    {
      option: "Better luck next time",
      style: {
        backgroundColor: "#3DA5E0",
        textColor: "white",
        padding: "20px",
      },
    },
    {
      option: "Won 2",
      style: { backgroundColor: "#34A24F", textColor: "white" },
    },
    {
      option: "Won uber pass",
      style: {
        backgroundColor: "#F9AA1F",
        textColor: "black",
        padding: "20px",
      },
    },
    {
      option: "Better luck next time",
      style: {
        backgroundColor: "#EC3F3F",
        textColor: "white",
        padding: "20px",
      },
    },
    {
      option: "Won a voucher",
      style: {
        backgroundColor: "#FF9000",
        textColor: "black",
        padding: "20px",
      },
    },
  ];

  const handleSpinClick = () => {
    const newPrizeNumber = Math.floor(Math.random() * data.length);
    setPrizeNumber(newPrizeNumber);
    setMustSpin(true);
  };

  return (
    <div className="flex flex-col items-center my-10 mx-auto">
      <div>
        <Wheel
          mustStartSpinning={mustSpin}
          prizeNumber={prizeNumber}
          outerBorderWidth={5}
          fontSize={16}
          data={data}
          onStopSpinning={() => {
            setMustSpin(false);
            setPrize(data[prizeNumber]?.option);
            localStorage.setItem("prize", data[prizeNumber]?.option);
          }}
        />
      </div>
      {!prize ? (
        <button
          onClick={handleSpinClick}
          className="mt-4 px-4 py-2 bg-accent text-white rounded-md hover:bg-secondary"
        >
          Spin
        </button>
      ) : (
        <Link
          to={"/shipping"}
          className="mt-4 px-4 py-2 bg-accent text-white rounded-md hover:bg-secondary"
        >
          Proceed To Checkout
        </Link>
      )}

      {prize && <p className="mt-6 font-bold">{prize}</p>}
    </div>
  );
};

export default SpinWheel;
