import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import { ThemeContext } from "../../Contexts/ThemeContext";
import { Helmet } from "react-helmet-async";

const Blog = () => {
  const { id } = useParams();
  const { blogs } = useContext(ThemeContext);

  const blog = blogs?.find((blog) => blog._id === id);

  function stripHTMLTags(html) {
    const tmpElement = document.createElement("div");
    tmpElement.innerHTML = html;
    return tmpElement.textContent || tmpElement.innerText || "";
  }

  return (
    <div className="px-[10%] py-10">
      <Helmet>
        <title>{`${blog?.title?.rendered}`} - Flormar Bangladesh</title>
        <meta
          name="description"
          content={stripHTMLTags(blog?.content.rendered.slice(1, 170))}
        />
        <link
          rel="canonical"
          href={`https://flormarbd.com/blog/${blog?._id}`}
        />
      </Helmet>
      <div>
        <div>
          <div className="bg-accent px-4 py-1 w-[150px] mx-auto">
            <p className="text-center font-bold text-primary">Skin Care</p>
          </div>
          <h1 className="text-2xl my-6 font-bold text-center">
            {blog?.title?.rendered}
          </h1>
          <div className="flex justify-center">
            <img src={blog?.yoast_head_json.og_image[0].url} alt="" />
          </div>
          <div
            className="mt-10 lg:p-16 text-secondary text-opacity-60 flex flex-col gap-4"
            dangerouslySetInnerHTML={{
              __html: blog?.content.rendered,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Blog;
