import React, { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretRight, faList } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { ThreeDots } from "react-loader-spinner";
import Product from "../Components/Shared/Product";
import { Helmet } from "react-helmet-async";

const Shop = () => {
  const [categoryProducts, setCategoryProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const { category } = useParams();
  const [list, setList] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [page, setPage] = useState(0);

  useEffect(() => {
    fetch(
      `https://server.flormarbd.com/shopProductCount`
    )
      .then((res) => res.json())
      .then((data) => {
        const count = data.count;
        const pages = Math.ceil(count / 50);
        setPageCount(pages);
      });
  }, [category]);

  useEffect(() => {
    setCategoryProducts([]);
    setLoading(true);
    fetch(
      `https://server.flormarbd.com/shop?page=${page}`
    )
      .then((res) => res.json())
      .then((data) => {
        setCategoryProducts(data);
        setLoading(false);
      });
    window.scrollTo(0, 0);
  }, [category, page]);

  return (
    <div className="2xl:w-[65%] lg:w-[75%] w-[95%] mx-auto mb-20">
      <Helmet>
        <title>Best Cosmetics In Bangladesh - Flormar Bangladesh</title>
        <meta
          name="description"
          content="Shop the best cosmetics in bangladesh at the best price from Flormar Bangladesh. Explore makeup for face, eyes, lips, nails, and accessories"
        />
        <link rel="canonical" href={`https://flormarbd.com/shop`} />
        <meta name="robots" content="INDEX, FOLLOW" />
        {/* Add JSON-LD structured data for the shop page */}
        <script type="application/ld+json">
          {`
            {
              "@context": "http://schema.org",
              "@type": "ItemList",
              "name": "Best Cosmetics In Bangladesh - Flormar Bangladesh",
              "description": "Shop the best cosmetics in bangladesh at the best price from Flormar Bangladesh. Explore makeup for face, eyes, lips, nails, and accessories",
              "itemListElement": [
                ${categoryProducts
                  .map(
                    (product, index) => `
                  {
                    "@type": "ListItem",
                    "position": ${index + 1},
                    "url": "https://flormarbd.com/product/${product?.slug}"
                  }
                `
                  )
                  .join(",")}
              ]
            }
          `}
        </script>
      </Helmet>
      <div className="my-10 w-[90%] 2xl:w-[full lg:w-full  mx-auto">
        <p className="text-[14px] font-semibold">
          Home
          <FontAwesomeIcon
            className="mx-2"
            icon={faCaretRight}
          ></FontAwesomeIcon>
          <h1 className="inline">Best Cosmetics In Bangladesh</h1>
        </p>
      </div>
      {/* <h1
        className={`w-[90%] 2xl:w-full lg:w-full mx-auto ${
          category?.includes("top 10") ? "text-center" : ""
        } font-bold my-10 text-[22px]`}
      >
        {category?.toUpperCase()}
      </h1> */}

      <div className="hidden lg:flex items-center mb-10">
        <button onClick={() => setList(false)}>
          <img
            width="25"
            height="25"
            src="https://img.icons8.com/sf-regular-filled/48/grid.png"
            alt="grid"
            className="inline mt-[-4px]"
          />
          Grid
        </button>
        <button onClick={() => setList(true)}>
          <FontAwesomeIcon className="mx-2" icon={faList}></FontAwesomeIcon>List
        </button>
      </div>
      {loading && (
        <div className="flex justify-center items-center h-[50vh]">
          <ThreeDots
            height="100"
            width="100"
            radius="10"
            color="#f34b7f"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={true}
          />
        </div>
      )}

      {list ? (
        <div className="list-container grid gap-6 grid-cols-1">
          {categoryProducts?.map((product) => (
            <Product key={product._id} list={list} product={product}></Product>
          ))}
        </div>
      ) : (
        <div className="grid grid-cols-2 gap-y-8 lg:gap-6 lg:grid-cols-4">
          {categoryProducts?.map((product) => (
            <Product key={product._id} product={product}></Product>
          ))}
        </div>
      )}
      {/*.................. pagination ....................*/}
      {pageCount > 1 && (
        <div className="pages mb-[100px] px-10">
          {[...Array(pageCount).keys()].map((index) => (
            <button
              className={page == index ? "selected" : ""}
              onClick={() => setPage(index)}
            >
              {index + 1}
            </button>
          ))}
        </div>
      )}
      {/*.................. pagination ....................*/}
    </div>
  );
};

export default Shop;
