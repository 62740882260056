import React, { useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useScroll from "../../Hooks/useScroll";
import { ThemeContext } from "../../Contexts/ThemeContext";
import "./Cart.css";

const Cart = ({ handlePopCart, popCart, handleSearch }) => {
  const location = useLocation();
  const [scrollPosition] = useScroll();

  const { cart } = useContext(ThemeContext);
  const navigate = useNavigate();

  let quantity = 0;
  cart?.forEach((product) => {
    quantity = quantity + product?.quantity;
  });

  return (
    <div className="flex-none">
      {/*----------------- Navbar Cart -----------------*/}
      <div
        className={` cart-icon  mobile-cart-position ${
          scrollPosition > 10 ? " cart-scrolled-50" : "cart-not-scrolled"
        } ${scrollPosition > 80 ? "cart-scrolled-80" : "cart-scrolled-50"} `}
      >
        <div className="indicator">
          {/*-------------- cart icon -------------*/}

          {location.pathname.includes("/cart") || (
            <div
              onClick={() => {
                handlePopCart(!popCart);
                // window.innerHeight<740&&navigate("/cart")
                if (window.innerWidth < 640) handleSearch(false);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className={`transition ease-in duration-200 ${
                  scrollPosition > 80
                    ? "h-7 w-7 cursor-pointer text-secondary "
                    : "h-7 w-7 text-secondary cursor-pointer"
                }`}
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"
                />
              </svg>

              <span
                className={`badge text-primary font-bold border-none cursor-pointer ${
                  scrollPosition > 80
                    ? "lg:badge-sm text-xs "
                    : "lg:badge-md lg:text-md"
                }  indicator-item bg-accent  flex justify-center items-center pt-[1px]`}
              >
                {quantity}
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Cart;
