import React, { useContext, useEffect, useState } from "react";
import "./Navbar.css";
import { Link, Navigate, useNavigate } from "react-router-dom";
import logo from "../../../Images/Flormar-Logo-Png-1.png";
import topBanner from "../../../Images/microsoftteams-image-1.jpg";
import { useAuthState } from "react-firebase-hooks/auth";
import auth from "../../../firebase.init";
import { signOut } from "firebase/auth";
import { ThemeContext } from "../../../Contexts/ThemeContext";
import SearchedProducts from "../../../Components/SearchedProducts/SearchedProducts";
import Cart from "../../../Components/Cart/Cart";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import useScroll from "../../../Hooks/useScroll";
import luviLogo from "../../../Images/Luvit-final-Logo.png";

const Navbar = ({ popCart, handlePopCart }) => {
  const [focus, setFocus] = useState(false);
  const { searchText, setSearchText } = useContext(ThemeContext);
  const otpUser = JSON.parse(localStorage.getItem("user"));
  const [searchedProducts, setSearchedProducts] = useState([]);
  const [searchedProductCount, setSearchedProductCount] = useState(0);
  const [scrollPosition] = useScroll();
  const navigation = useNavigate();
  let highPriorityProducts = [];

  useEffect(() => {
    if (searchText.length > 2) {
      fetch(
        `https://flormar-web-server-nayem55-flormar-bd.vercel.app/searchProduct/${searchText}/0`
      )
        .then((res) => res.json())
        .then((data) => setSearchedProducts(data));
    }
  }, [searchText.length]);

  useEffect(() => {
    fetch(
      `https://flormar-web-server-nayem55-flormar-bd.vercel.app/searchedProductCount?searchText=${searchText}`
    )
      .then((res) => res.json())
      .then((data) => {
        const count = data.count;
        setSearchedProductCount(count);
      });
  }, [searchText]);

  return (
    <div className="w-full hidden lg:block z-10">
      <div>
        <img className="w-[100vw]" src={topBanner} alt="" />
      </div>
      <div className="flex bg-[#f5f5f5] justify-end px-10 py-4 pr-20 items-center">
        <a href="https://luvit.com.bd" target="--blank">
          <img className="w-[60px] mr-10" src={luviLogo} alt="" />
        </a>
        <Link to="/shop" className="font-bold hover:text-accent mr-16">
          Shop
        </Link>
        <Link to="/campaign" className="font-bold hover:text-accent mr-16">
          Campaign
        </Link>

        <Link className="hover:text-accent font-bold flex mr-16" to="/malls">
          Malls
        </Link>

        <Link to="/trackOrder" className="hover:text-accent font-bold mr-16">
          Track Order
        </Link>
      </div>

      <div className="navbar px-0 py-6 lg:px-10 flex justify-between">
        {/*.................. logo ...................... */}
        <div className="cursor-pointer">
          <Link to="/">
            <img src={logo} className="h-[50px]" alt="" />
          </Link>
        </div>
        {/*.......... search bar............. */}
        <div className="form-control text-black 2xl:w-[60%] lg:w-[50%] relative">
          <form
            className="input-group"
            onSubmit={(e) => {
              e.preventDefault();
              navigation(`/search/${searchText}`);
              setFocus(false);
            }}
          >
            <input
              type="text"
              className="border border-r-0 border-[#cccccc] w-[100%] px-4"
              placeholder="Search in flormar"
              onFocus={() => setFocus(true)}
              onBlur={() => {
                setTimeout(() => setFocus(false), 200);
              }}
              onChange={(e) => setSearchText(e.target.value)}
            />
            <button
              type="submit"
              className="btn btn-rounded bg-primary border border-l-0 border-[#cccccc]"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6 hover:text-primary"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                />
              </svg>
            </button>
          </form>

          {/*......... Search result............ */}
          <div>
            <SearchedProducts
              highPriorityProducts={highPriorityProducts}
              searchedProducts={searchedProducts}
              focus={focus}
              searchedProductCount={searchedProductCount}
            />
          </div>
        </div>
        <div>
          <div
            className={` cart-icon mobile-cart-position ${
              scrollPosition > 10 ? " cart-scrolled-50" : "cart-not-scrolled"
            } ${
              scrollPosition > 80 ? "cart-scrolled-80" : "cart-scrolled-50"
            } `}
          >
            {otpUser?.phone ? (
              <Link to="/dashboard" className="hover:text-accent">
                <FontAwesomeIcon
                  className="text-[24px] mr-10 mb-2"
                  icon={faUser}
                ></FontAwesomeIcon>
              </Link>
            ) : (
              <Link to="/customerDashboard" className="hover:text-accent">
                <FontAwesomeIcon
                  className="text-[24px] mr-10 mb-2"
                  icon={faUser}
                ></FontAwesomeIcon>
              </Link>
            )}
          </div>
          <Cart popCart={popCart} handlePopCart={handlePopCart}></Cart>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
