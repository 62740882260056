import React from "react";
import { Link } from "react-router-dom";

const PaymentCancelled = () => {
  return (
    <div className="h-[60vh]">
      <div className="w-[50%] mx-auto flex flex-col items-center">
        <p className="text-center text-4xl mt-[10%]">Payment Cancelled!</p>
        {/* <p className="text-center text-4xl mt-10">
          Something went wrong. Please try again
        </p> */}
        <Link
          className="mx-auto mt-10 bg-secondary text-primary px-2 py-1 rounded hover:bg-accent"
          to="/"
        >
          Return Home
        </Link>
      </div>
    </div>
  );
};

export default PaymentCancelled;
