import React from "react";
import img from "../Images/about.webp";
import { Helmet } from "react-helmet-async";

const About = () => {
  return (
    <div className="p-[6%] sm:px-[10%] sm:py-[2%]">
      <Helmet>
        <title>About - Flormar Bangladesh</title>
        <meta
          name="description"
          content="About Flormar Bangladesh."
        />
      </Helmet>
      <p className="text-2xl  my-10 text-center">
        Flormar is the No. 1 Turkish Cosmetics Brand!
      </p>
      <div className="flex flex-col lg:flex-row gap-10 sm:items-center">
        <img className="w-[100%] lg:w-[50%]" src={img} alt="" />
        <div className="w-[100%] lg:w-[50%]">
          <p className="text-accent  my-4">
            An E-Commerce Website of Bangladesh
          </p>
          <p className="text-4xl ">About Flormar Brand</p>
          <p className=" my-4">
            Flormar is a Turkish cosmetics brand that offers a wide range of
            makeup and beauty products, including lipsticks, eyeshadows,
            mascaras, blushes, foundations, and more. The brand is known for its
            high-quality products, stylish packaging, and affordable prices.
            Flormar has a strong presence in Turkey and other countries in the
            Middle East, and has also expanded to Europe and other regions.
          </p>
          <p className=" my-4">
            Flormar is a cosmetics brand that sells its products through various
            channels, including an e-commerce website. If you have a question or
            concern about an order placed on the Flormar e-commerce site, I
            recommend reaching out to their customer service for assistance.
            They should be able to provide information about the availability of
            products, the status of your order, and any other questions you may
            have about shopping on their website..
          </p>
          <p className="text-secondary my-4">
            Flormar is a cosmetics brand that offers a variety of beauty
            products such as makeup, nail polish, and skincare products. Their
            e-commerce website allows customers to purchase their products
            online and have them delivered directly to their door.
          </p>
          <p className="text-secondary">
            The website may feature a range of products including their latest
            collections, best-selling items, and special deals. Customers can
            create an account, add items to their cart, and complete the
            checkout process using a variety of payment options. Shipping,
            delivery, and return policies may vary depending on the customer's
            location, so it's a good idea to review these before making a
            purchase.
          </p>
          <p
              className="hover:text-secondary ease-in-out duration-200 font-bold cursor-pointer mt-6"
            >
              Trade License No : TRAD/DNCC/137645/2022
            </p>
        </div>
      </div>
    </div>
  );
};

export default About;
