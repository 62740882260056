import React, { useState } from "react";
import "./VariationSlider.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay, Pagination } from "swiper/modules";
import { Link } from "react-router-dom";

import "swiper/css";
import "swiper/css/pagination";
import { computeHeadingLevel } from "@testing-library/react";

const VariationSlider = ({ variations,slug }) => {
  const [hover,setHover] = useState(false)
  const [currentIndex,setCurrentIndex] = useState()
  let quantity;
  if (window.innerWidth < 640) {
    quantity = 5;
  } else quantity = 8;
  return (
    <div className="homeBrand px-4 sm:px-0 ">
      <Swiper
        slidesPerView={quantity}
        spaceBetween={0}
        navigation={quantity > 5?true:false}
        // pagination={false}
        // autoplay={{
        //   delay: 2500,
        //   disableOnInteraction: false,
        // }}
        modules={[Navigation, Autoplay]}
        className="variation-swiper"
      >
        {variations?.map((variation,i) => {
          let code = variation?.code;
          let style = { backgroundColor: code };
          return (
          <SwiperSlide>
            <Link to={`/product/${variation.product_slug}`}>
              <div
              onMouseEnter={()=>{
                setHover(true);
                setCurrentIndex(i)
              }}
              onMouseLeave={()=>{
                setHover(false);
                setCurrentIndex()
              }}
                className={`w-[30px] h-[30px] mt-3 cursor-pointer rounded-full ${slug == variations[i]?.product_slug ?"border border-secondary border-[2px]":""}`}
                style={style}
              >
                  <p className={`${(hover && currentIndex==i)?"opacity-60 absolute":"opacity-0 hidden"} bg-secondary text-primary px-2 ease-in-out duration-200 w-[150px] text-center mt-[-25px] ml-[-40px]`}>{variation.color}</p>
              </div>
            </Link>
          </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
};

export default VariationSlider;
