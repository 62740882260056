import React from "react";
import img from "../Images/flormar-black-pink.png";
import img1 from "../Images/shipping.jpg";


const Terms = () => {
  return (
    <div className="px-[6%] py-[10%] sm:px-[10%] sm:py-[6%]">
      <p className="text-xl text-center font-bold mb-10">Terms & Conditions</p>
      <p>
        We, at Flormar Bangladesh, value the trust you place in us. That’s why
        we insist upon the highest standards for secure transactions and
        customer information privacy. Please read the following statement to
        learn about our information gathering and dissemination practices.
      </p>
      <p className="font-bold mt-6">Terms of Use</p>
      <p className="mt-4 text-secondary text-opacity-70">
        1. Our privacy policy is subject to change at any time without notice. To
        make sure you are aware of any changes, please review this policy
        periodically. Floarmar Bangladesh has the all rights to cancel any order
        for anytime.<br/>2. By visiting this Website you agree to be bound by the terms
        and conditions of this Privacy Policy. If you do not agree please do not
        use or access our Website.<br/>3. By mere use of the Website, you expressly
        consent to our use and disclosure of your personal information in
        accordance with this Privacy Policy. This Privacy Policy is incorporated
        into and subject to the Terms of Use.<br/><br/> The delivery might vary depending
        upon different circumstances. Please note that announced timeframe/line
        must be calculated in working days and it is only subject to estimation.
        It might take longer than usual timeframe/line to be followed by our shipping agency.
        Delivery might be delayed due to natural disaster, force majeure events
        which include, but not limited to, political unrest, political event,
        national/public holidays/official holiday(s), etc.<br/>1. Product availability
        may vary between online and physical shops.<br/>2. Prices are subject to change
        without notice, and discounts and promotions may differ.<br/>3. Returns and
        exchanges are governed by the respective platform's policies.<br/><br/> The orders
        are subject to availability of stock. If there is any problem with stock
        then the order can be canceled at any time, irrespective of any
        duration.
      </p>
    </div>
  );
};

export default Terms;
