import React from "react";
import { useContext } from "react";
import { ThemeContext } from "../Contexts/ThemeContext";

const Variation = () => {
  const { products } = useContext(ThemeContext);
  const maxiBrush = products?.filter((product) =>
    product?.name.includes("Flormar Mono Eye Shadow")
  );

  return (
    <div>
      {maxiBrush?.map((brush) => (
        <pre key={brush.slug}>
          {`
            {
              color: "${""}",
              code: "${""}",
              product_slug: "${brush.slug}"
            },
          `}
        </pre>
      ))}
    </div>
  );
};

export default Variation;

