import { faPenToSquare, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { ThreeDots } from "react-loader-spinner";
import { Link } from "react-router-dom";

const ReviewList = () => {
  const [reviews, setReviews] = useState([]);
  const [searchedText, setSearchedText] = useState("");
  const [loading, setLoading] = useState(false);
  const [deletedId, setDeletedId] = useState();
  const [pageCount, setPageCount] = useState(0);
  const [page, setPage] = useState(0);
  const [status, setStatus] = useState("");

  useEffect(() => {
    fetch(
      "https://server.flormarbd.com/reviewCount"
    )
      .then((res) => res.json())
      .then((data) => {
        const count = data.count;
        const pages = Math.ceil(count / 50);
        setPageCount(pages);
      });
  }, []);

  useEffect(() => {
    setReviews([]);
    setLoading(true);
    fetch(
      `https://server.flormarbd.com/allReviews?page=${page}`
    )
      .then((res) => res.json())
      .then((data) => {
        setReviews(data);
        setLoading(false);
      });
  }, [page]);

  const handleDelete = (id) => {
    fetch(
      `https://server.flormarbd.com/review/${id}`,
      {
        method: "delete",
      }
    )
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
      });
    toast.success("Review deleted sucessfully");
    const rest = reviews.filter((review) => review._id !== id);
    setReviews(rest);
  };

  const handleSearch = () => {
    //   const searchedCoupon = reviews.filter(coupon=>coupon.code.toLowerCase().includes(searchedText.toLowerCase()))
    //   setReviews(searchedCoupon);
  };

  const handleEditReviews = (id) => {
    const data = {
      status: status,
    };

    fetch(
      `https://server.flormarbd.com/review/${id}`,
      {
        method: "put",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify(data),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
      });
    toast.success("Review edited successfully");
  };

  return (
    <div>
      <div className="flex justify-between mx-12">
        <div className="flex">
          <h1 className="mt-12 mr-12 text-2xl">Reviews</h1>
        </div>
        {/* <div>
          <input
            type="text"
            placeholder="Search..."
            className="border border-secondary px-4 py-1 border-opacity-40"
            onChange={(e) => setSearchedText(e.target.value)}
            value={searchedText}
          ></input>
          <button
            onClick={handleSearch}
            className="px-6 mt-12 ml-4 py-1 border border-accent bg-primary text-accent hover:bg-accent hover:text-primary ease-in-out duration-300 "
          >
            Search
          </button>
        </div> */}
      </div>

      {/*.................. pagination ....................*/}
      {pageCount > 1 && (
        <div className="pages">
          {[...Array(pageCount).keys()].map((index) => (
            <button
              className={page == index ? "selected" : ""}
              onClick={() => {
                setPage(index);
              }}
            >
              {index + 1}
            </button>
          ))}
        </div>
      )}
      {/*.................. pagination ....................*/}

      {reviews.length < 1 && loading ? (
        <div className="flex justify-center items-center h-[90vh]">
          <ThreeDots
            height="100"
            width="100"
            radius="10"
            color="#f34b7f"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={true}
          />
        </div>
      ) : (
        <div className="overflow-x-auto">
          <table className="product-table">
            {/* head */}
            <thead className="bg-accent text-primary">
              <tr>
                <th></th>
                <th>Customer</th>
                <th>Rating</th>
                <th>Review</th>
                <th>Status</th>
                <th>Product</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {/*................table row............... */}
              {reviews?.map((review) => (
                <tr className="">
                  <th>
                    <label>
                      <input type="checkbox" className="checkbox" />
                    </label>
                  </th>
                  <td className="w-[20%]">
                    <div className="flex flex-col">
                      <div>
                        <p className="font-bold">{review?.reviewer}</p>
                      </div>
                      <div>
                        <p className="font-bold">{review?.reviewer_email}</p>
                      </div>
                    </div>
                  </td>
                  <td>{review?.rating}</td>
                  <td>
                    <div
                      className="w-[300px]"
                      dangerouslySetInnerHTML={{ __html: review?.review }}
                    />
                  </td>
                  <td>
                    <select
                      onChange={(e) => setStatus(e.target.value)}
                      className="border border-secondary"
                    >
                      <option>{review?.status}</option>
                      <option>approved</option>
                      <option>unapproved </option>
                    </select>
                  </td>
                  <td>{review?.product_name}</td>
                  <td>
                    <button
                      onClick={() => handleEditReviews(review?._id)}
                      className="px-2 py-1 bg-accent text-primary mr-4 hover:bg-secondary ease-in-out duration-200"
                    >
                      Save
                    </button>
                    <Link
                      onClick={() => {
                        setDeletedId(review?._id);
                        document.getElementById("confirmation").showModal();
                      }}
                    >
                      <FontAwesomeIcon
                        className="text-[#b14444]"
                        icon={faTrash}
                      ></FontAwesomeIcon>
                    </Link>
                    <dialog
                      id="confirmation"
                      className="bg-accent rounded p-10"
                    >
                      <form method="dialog" className="">
                        <button className="btn text-primary btn-sm btn-circle btn-ghost absolute right-2 top-2">
                          ✕
                        </button>
                        <h3 className="font-bold text-lg text-primary">
                          Confirm Delete !
                        </h3>
                        <p className="py-4 text-primary">
                          Are you sure you want to delete this item ?
                        </p>
                        <button
                          onClick={() => handleDelete(deletedId)}
                          className="btn py-3 bg-primary text-accent border-none"
                        >
                          Confirm
                        </button>
                      </form>
                    </dialog>
                  </td>
                </tr>
              ))}
            </tbody>
            {/* foot */}
            <tfoot className="bg-accent text-primary">
              <tr>
                <th></th>
                <th>Customer</th>
                <th>Rating</th>
                <th>Review</th>
                <th>Status</th>
                <th>Product</th>
                <th>Actions</th>
              </tr>
            </tfoot>
          </table>
        </div>
      )}
      {/*.................. pagination ....................*/}
      {pageCount > 1 && (
        <div className="pages mb-[100px]">
          {[...Array(pageCount).keys()].map((index) => (
            <button
              className={page == index ? "selected" : ""}
              onClick={() => setPage(index)}
            >
              {index + 1}
            </button>
          ))}
        </div>
      )}
      {/*.................. pagination ....................*/}
    </div>
  );
};

export default ReviewList;

//npm install react react-dom react-modal
