import React, { useContext, useState } from 'react';
import { ThemeContext } from '../../../Contexts/ThemeContext';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

const MobileAccessories = () => {
    const [linkFiveHover, setLinkFiveHover] = useState(false);
    return (
        <div
          className={`mobile-perfume transition-all ease-in-out duration-300`}
        >
          <button
            onClick={() => {
              setLinkFiveHover(!linkFiveHover)
            }}
            className={`mr-10 w-[85%] text-start relative ${linkFiveHover?"text-accent":"text-secondary"} font-bold `}
          >
            ACCESSORIES
            <FontAwesomeIcon
              className=" w-3 absolute right-0"
              icon={faChevronDown}
            ></FontAwesomeIcon>
          </button>

          <div className={`mobile-menu-hover ${
            linkFiveHover ? "grid-transition text-start px-4 py-4 " : ""
          } text-black `}>

          <div>
          <hr className=" w-full text-white my-2" />
            <Link
            to={`/product-category/accessories`}
            className=" hover:text-accent">All products</Link>
          <hr className=" w-full text-white my-2" />
            <Link
            to={`/product-category/Brush & Sponge`}
            className=" hover:text-accent">brush & sponge</Link>

            <hr className=" w-full text-white my-2" />
            <Link
            to={`/product-category/Makeup Brush Set`} className=" hover:text-accent">makeup brush set</Link>

            <hr className=" w-full text-white my-2" />
            <Link
            to={`/product-category/Eye & Brow Brush`} className=" hover:text-accent">eye & brow brush</Link>

            <hr className=" w-full text-white my-2" />
            <Link 
            to={`/product-category/Makeup Applicator`} className=" hover:text-accent">makeup applicator</Link>

            <hr className=" w-full text-white my-2" />
            <Link 
            to={`/product-category/Face Brush`} className=" hover:text-accent">face brush</Link>

            <hr className=" w-full text-white my-2" />
            <Link 
            to={`/product-category/Artificial Nails`} className=" hover:text-accent">artificial nails</Link>
            <hr className=" w-full text-white my-2" />
            <Link 
            to={`/product-category/Nail Art`} className=" hover:text-accent">nail art</Link>
            <hr className=" w-full text-white my-2" />
            <Link
            to={`/product-category/Manicure Set`} className=" hover:text-accent">manicure set</Link>
          </div>
          </div>
        </div>
    );
};

export default MobileAccessories;