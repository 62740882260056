import { ThemeContext } from "../../Contexts/ThemeContext";
import useScroll from "../../Hooks/useScroll";
import { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

const FaceGroup = () => {
  const [linkOneHover, setLinkOneHover] = useState(false);
  const [scrollPosition] = useScroll();
  const [subMenuHover, setSubMenuHover] = useState(false);

  return (
    <div
      onMouseEnter={() => setLinkOneHover(true)}
      onMouseLeave={() => setLinkOneHover(false)}
      className={`transition-all ease-in-out min-h-[50px] duration-300 flex items-center ${
        scrollPosition > 80 ? "min-h-[65px]" : ""
      }`}
    >
      <Link
        to={`/product-category/face`}
        className={`mr-10 block font-bold ${
          scrollPosition > 80
            ? "hover:text-accent text-secondary"
            : "text-secondary hover:text-accent"
        }`}
      >
        FACE
        <FontAwesomeIcon
          className="w-3 ms-2"
          icon={faChevronDown}
        ></FontAwesomeIcon>
      </Link>

      {/* Face Hover product-Category  */}

      <div
        className={`menu-hover flex justify-between ${
          linkOneHover ? "block text-start" : "hidden relative"
        } ${
          scrollPosition > 80 ? "menu-scrolled" : ""
        } px-4  pb-4 w-[200px] text-black `}
      >
      <div>
      <Link to="/product-category/foundation" className=" hover:text-accent block">
          Foundation
        </Link>
        <Link to="/product-category/blush on" className=" hover:text-accent block">
          Blush On
        </Link>
        <Link to="/product-category/face powder" className=" hover:text-accent block">
          Face Powder
        </Link>

        <Link to="/product-category/concealer" className=" hover:text-accent block">
          Concealer
        </Link>

        <Link to="/product-category/primer" className=" hover:text-accent block">
          Primer
        </Link>

        <Link to="/product-category/highlighter" className=" hover:text-accent block">
          Contour/Highlighter
        </Link>

        <Link to="/product-category/gel" className=" hover:text-accent block">
          Gel/Cream
        </Link>

        <Link to="/product-category/fixer" className=" hover:text-accent block">
          Fixer
        </Link>

        <Link to="/product-category/mask" className=" hover:text-accent block">
          Mask
        </Link>

        <Link to="/product-category/tonic" className=" hover:text-accent block">
          Tonic
        </Link>

        <Link to="/product-category/remover" className=" hover:text-accent block">
          Cleaner/Remover
        </Link>
      </div>
      <div>
        <img src="https://afb801.cdn.akinoncloud.com/cms/2023/01/03/f97bcabc-32bf-4717-85b0-ce9830ddf0fc.jpg" alt=""/>
      </div>
      </div>
    </div>
  );
};

export default FaceGroup;
