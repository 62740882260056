import React, { useContext, useState } from "react";
import { ThemeContext } from "../../../Contexts/ThemeContext";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

const MobileNails = () => {
  const [linkFourHover, setLinkFourHover] = useState(false);
  return (
    <div className={`mobile-perfume transition-all ease-in-out duration-300`}>
      <button
        onClick={() => setLinkFourHover(!linkFourHover)}
        className={`mr-10 w-[85%] text-start relative ${
          linkFourHover ? "text-accent" : "text-secondary"
        } font-bold `}
      >
        Nails
        <FontAwesomeIcon
          className=" w-3 absolute right-0"
          icon={faChevronDown}
        ></FontAwesomeIcon>
      </button>

      <div
        className={`mobile-menu-hover ${
          linkFourHover ? "grid-transition text-start px-4 py-4 " : ""
        } text-black `}
      >
        <div>
          {/* <hr className=" w-full text-white my-2" />
          <Link
            to={`/product-category/nail polish`}
            className=" hover:text-accent"
          >
            All products
          </Link> */}
          <hr className=" w-full text-white my-2" />
          <Link
            to={`/product-category/nail polish`}
            className=" hover:text-accent"
          >
            Nail Polish
          </Link>

          <hr className=" w-full text-white my-2" />
          <Link
            to={`/product-category/nail enamel remover`}
            className=" hover:text-accent"
          >
            Nail Polish Remover
          </Link>

          <hr className=" w-full text-white my-2" />
          <Link to={`/product-category/nail care`} className=" hover:text-accent">
            Nail Care
          </Link>

          <hr className=" w-full text-white my-2" />
          <Link to={`/product-category/nail fixer`} className=" hover:text-accent">
            Nail Fixer
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MobileNails;
