import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { Navigate, useLocation } from "react-router-dom";
import auth from "../firebase.init";
import { signOut } from "firebase/auth";
import { useContext } from "react";
import { ThemeContext } from "../Contexts/ThemeContext";

const AdminRoute = ({ children }) => {
  let location = useLocation();

  // let [user, loading] = useAuthState(auth);
  let { user, userLoading, isAdmin, isAdminLoading } = useContext(ThemeContext);
  // const [isAdmin, setIsAdmin] = useState(false);
  // const [isAdminLoading, setIsAdminLoading] = useState(false);
  // let location = useLocation();
  // useEffect(() => {
  //   setIsAdminLoading(true);
  //   if (user?.phoneNumber) {
  //     fetch(
  //       `https://flormar-web-server-nayem55-flormar-bd.vercel.app/users/admin/${user?.phoneNumber}`
  //     )
  //       .then((res) => res.json())
  //       .then((data) => {
  //         setIsAdmin(data.isAdmin);
  //         setIsAdminLoading(false);
  //       });
  //   } else {
  //     setTimeout(() => {
  //       setIsAdminLoading(false);
  //       <Navigate to="/customerDashboard" state={{ from: location }} replace />;
  //     }, 5000);
  //   }
  // }, [user?.phoneNumber]);

  if (userLoading || isAdminLoading) {
    return (
      <div className="flex justify-center items-center h-[90vh]">
        <progress className="progress  w-56"></progress>
      </div>
    );
  }

  if (user && isAdmin) {
    return children;
  }
  // signOut(auth);
  return (
    <Navigate to="/customerDashboard" state={{ from: location }} replace />
  );
};

export default AdminRoute;
