import React, { useState, useRef, useCallback } from 'react';
import html2canvas from 'html2canvas';

const FrameUploader = () => {
  const [image, setImage] = useState(null);
  const [scale, setScale] = useState(1);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const frameUrl = 'https://i.ibb.co.com/h7KNHdT/FRAME.png'; // Replace with your frame path
  const imageRef = useRef();

  const draggingRef = useRef(false);
  const startPosRef = useRef({ x: 0, y: 0 });
  const currentPosRef = useRef({ x: 0, y: 0 });
  const animationFrameRef = useRef(null);

  // Handle image upload
  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => setImage(reader.result);
      reader.readAsDataURL(file);
    }
  };

  // Capture image with frame and download
  const handleDownload = async () => {
    if (imageRef.current) {
      const canvas = await html2canvas(imageRef.current, {
        useCORS: true,
        scale: 2,
      });
      const link = document.createElement('a');
      link.href = canvas.toDataURL('image/png');
      link.download = 'framed-image.png';
      link.click();
    }
  };

  // Handle scale adjustment
  const handleScaleChange = (e) => {
    setScale(parseFloat(e.target.value));
  };

  // Start dragging
  const startDragging = (clientX, clientY) => {
    draggingRef.current = true;
    startPosRef.current = {
      x: clientX - currentPosRef.current.x,
      y: clientY - currentPosRef.current.y,
    };
  };

  const handleMouseDown = (e) => startDragging(e.clientX, e.clientY);
  const handleTouchStart = (e) => startDragging(e.touches[0].clientX, e.touches[0].clientY);

  // Dragging movement (optimized with requestAnimationFrame)
  const handleMove = useCallback((clientX, clientY) => {
    if (!draggingRef.current) return;

    currentPosRef.current = {
      x: clientX - startPosRef.current.x,
      y: clientY - startPosRef.current.y,
    };

    if (!animationFrameRef.current) {
      animationFrameRef.current = requestAnimationFrame(() => {
        setPosition({ ...currentPosRef.current });
        animationFrameRef.current = null;
      });
    }
  }, []);

  const handleMouseMove = (e) => {
    e.preventDefault();  // Prevent screen scroll on drag
    handleMove(e.clientX, e.clientY);
  };

  const handleTouchMove = (e) => {
    e.preventDefault();  // Prevent screen scroll on drag
    handleMove(e.touches[0].clientX, e.touches[0].clientY);
  };

  // End dragging
  const stopDragging = () => {
    draggingRef.current = false;
    if (animationFrameRef.current) {
      cancelAnimationFrame(animationFrameRef.current);
      animationFrameRef.current = null;
    }
  };

  return (
    <div
      className="flex flex-col items-center p-4 bg-gray-100 h-fit-content py-16"
      onMouseMove={handleMouseMove}
      onMouseUp={stopDragging}
      onTouchMove={handleTouchMove}
      onTouchEnd={stopDragging}
    >
      <h1 className="text-2xl text-center font-bold mb-6">Upload Image and Apply Frame</h1>

      {/* Upload Button */}
      <input
        type="file"
        accept="image/*"
        onChange={handleImageUpload}
        className="mb-4 p-2 border border-gray-300 rounded"
      />

      {/* Image Adjustment Controls */}
      {image && (
        <div className="flex flex-col items-center">
          {/* Scale Adjuster */}
          <label className="mb-2">Adjust Size:</label>
          <input
            type="range"
            min="0.5"
            max="2"
            step="0.01"
            value={scale}
            onChange={handleScaleChange}
            className="mb-4 w-64"
          />
        </div>
      )}

      {/* Frame Preview */}
      {image && (
        <div
          className="relative w-64 h-64 border border-gray-300 bg-white overflow-hidden"
          ref={imageRef}
        >
          {/* Uploaded Image */}
          <img
            src={image}
            alt="Uploaded"
            style={{
              transform: `translate(${position.x}px, ${position.y}px) scale(${scale})`,
              cursor: 'move',
            }}
            className="absolute inset-0 object-cover"
            onMouseDown={handleMouseDown}
            onTouchStart={handleTouchStart}
          />

          {/* Static Frame Overlay */}
          <img
            src={frameUrl}
            alt="Frame"
            className="absolute inset-0 w-full h-full pointer-events-none"
          />
        </div>
      )}

      {/* Download Button */}
      {image && (
        <button
          onClick={handleDownload}
          className="mt-4 px-4 py-2 bg-accent text-white rounded hover:bg-black ease-in-out duration-200"
        >
          Download Image with Frame
        </button>
      )}
    </div>
  );
};

export default FrameUploader;
