import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { ThemeContext } from "../../../Contexts/ThemeContext";

const MobileFace = () => {
  const [linkOneHover, setLinkOneHover] = useState(false);
  const [subMenuHover, setSubMenuHover] = useState(false);

  return (
    <div className={`mobile-perfume transition-all ease-in-out duration-300`}>
      <button
        onClick={() => setLinkOneHover(!linkOneHover)}
        className={`mr-10 w-[85%] text-start relative ${
          linkOneHover ? "text-accent" : "text-secondary"
        } font-bold `}
      >
        FACE
        <FontAwesomeIcon
          className=" w-3 absolute right-0"
          icon={faChevronDown}
        ></FontAwesomeIcon>
      </button>

      {/* Face Hover product-Category  */}

      <div
        className={`mobile-menu-hover ${
          linkOneHover ? "grid-transition text-start px-4 py-4 " : ""
        } text-black `}
      >
        <div>
          <hr className=" w-full my-2 text-accent" />
          <Link
            to="/product-category/face"
            className=" hover:text-accent"
          >
            All Products
          </Link>
          <hr className=" w-full my-2 text-accent" />
          <Link
            to="/product-category/foundation"
            className=" hover:text-accent"
          >
            Foundation
          </Link>
        <hr className=" w-full my-2 text-accent" />
        <Link
          to="/product-category/blush on"
          className=" hover:text-accent"
        >
          Blush On
        </Link>
        <hr className=" w-full my-2" />
        <Link
          to="/product-category/face powder"
          className=" hover:text-accent"
        >
          Face Powder
        </Link>
        <hr className=" w-full my-2" />
        <Link
          to="/product-category/concealer"
          className=" hover:text-accent"
        >
          Concealer
        </Link>
        <hr className=" w-full my-2" />
        <Link
          to="/product-category/primer"
          className=" hover:text-accent"
        >
          Primer
        </Link>
        <hr className=" w-full my-2" />
        <Link
          to="/product-category/highlighter"
          className=" hover:text-accent"
        >
          Contour/Highlighter
        </Link>
        <hr className=" w-full my-2" />
        <Link
          to="/product-category/gel"
          className=" hover:text-accent"
        >
          Gel/Cream
        </Link>
        <hr className=" w-full my-2" />
        <Link
          to="/product-category/fixer"
          className=" hover:text-accent"
        >
          Fixer
        </Link>
        <hr className=" w-full my-2" />
        <Link
          to="/product-category/mask"
          className=" hover:text-accent"
        >
          Mask
        </Link>
        <hr className=" w-full my-2" />
        <Link
          to="/product-category/tonic"
          className=" hover:text-accent"
        >
          Tonic
        </Link>
        <hr className=" w-full my-2" />
        <Link
          to="/product-category/remover"
          className=" hover:text-accent"
        >
          Cleaner/Remover
        </Link>
        </div>
      </div>
    </div>
  );
};

export default MobileFace;
