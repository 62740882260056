import Product from "../Shared/Product";
import { ThemeContext } from "../../Contexts/ThemeContext";
import { useContext } from "react";
import "./SummerMakeup.css";
import { Link } from "react-router-dom";

const SummerMakeup = () => {
  const { summerMakeup } = useContext(ThemeContext);

  return (
    <div className="featured-collections flex flex-col px-[10px] items-center sm:px-[0px] 2xl:w-[65%] lg:w-[75%] mx-auto">
      <h2 className="text-center text-xl text-accent font-bold mt-[50px] sm:text-2xl">
        BEST MAKEUP TRENDS
      </h2>
      <div className="w-[200px] mx-auto h-1 bg-accent my-6 mb-10"></div>
      <div className="grid grid grid-cols-2  lg:grid-cols-4 sm:gap-6 featuredCollection">
        {summerMakeup?.slice(0,8)?.map((product) => (
          <Product product={product}></Product>
        ))}
      </div>
      <Link to="/product-category/eye shadow" className=" text-secondary hover:text-accent font-bold sm:my-10 underline">
        View all products
      </Link>
    </div>
  );
};

export default SummerMakeup;
