import Product from "../Shared/Product";
import { ThemeContext } from "../../Contexts/ThemeContext";
import { useContext } from "react";
import "./MostFavorite.css";
import { Link } from "react-router-dom";

const MostFavorite = () => {
  const { favProducts } = useContext(ThemeContext);

  return (
    <div className="most-favorite flex flex-col items-center px-[10px] pt-10 2xl:px-[18%] lg:px-[15%] mx-auto">
      <h3 className="text-center text-2xl font-bold text-accent">
        MOST POPULAR
      </h3>
      <div className="w-[130px] mx-auto h-1 bg-accent my-6 mb-10"></div>
      <div className="grid grid-cols-2 sm:gap-6 lg:grid-cols-4">
        {favProducts?.slice(0,8)?.map((product) => (
          <Product key={product?._id} product={product}></Product>
        ))}
      </div>
      <Link to="/product-category/nail polish" className=" text-secondary hover:text-accent font-bold mb-10 sm:my-10 underline">
        View all products
      </Link>
    </div>
  );
};

export default MostFavorite;
