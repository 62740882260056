import React, { useContext, useEffect, useState } from "react";
import { ThreeDots } from "react-loader-spinner";
import { Link } from "react-router-dom";
import { ThemeContext } from "../../Contexts/ThemeContext";
import { Helmet } from "react-helmet-async";

const Blogs = () => {
  const { blogs, blogLoading } = useContext(ThemeContext);
  return (
    <div className="sm:m-10">
      <Helmet>
        <title>Blogs - Flormar Bangladesh</title>
        <meta name="description" content="Explore cosmetic insights, skincare tips, haircare tricks for vitality, and evaluations of products. We divulge top-notch beauty guidance from professionals, makeup artisans, and industry insiders." />
        <link
          rel="canonical"
          href={`https://flormarbd.com/blogs`}
        />
      </Helmet>
      {blogLoading && (
        <div className="flex justify-center items-center h-[50vh]">
          <ThreeDots
            height="100"
            width="100"
            radius="10"
            color="#f34b7f"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={true}
          />
        </div>
      )}
      <div className="grid grid-cols-1 gap-y-8 lg:gap-6 lg:grid-cols-2">
        {blogs.map((blog) => (
          <div className="flex flex-col justify-center items-center mb-[40px] p-[16px] rounded">
            <div className="flex flex-col items-center">
              <img src={blog?.yoast_head_json.og_image[0].url} alt="" />
              <div className="bg-accent px-4 py-1 mt-[-15px] mb-10">
                <p className="text-primary font-bold">Skin Care</p>
              </div>
            </div>
            <p className="font-bold mb-6 text-xl">{blog?.title?.rendered}</p>
            <div
              className="limit-lines"
              dangerouslySetInnerHTML={{
                __html: blog.content.rendered.slice(0, 260) + ".....",
              }}
            />
            <Link
              to={`/blog/${blog._id}`}
              className="mt-6 hover:text-accent ease-in-out duration-200 font-bold"
            >
              <p>CONTINUE READING</p>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Blogs;
