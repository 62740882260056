import React, { useContext, useState } from "react";
import { ThemeContext } from "../../../Contexts/ThemeContext";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

const MobileEyes = () => {
  const [linkTwoHover, setLinkTwoHover] = useState(false);
  const [subMenuHover, setSubMenuHover] = useState(false);
  return (
    <div className={`mobile-perfume transition-all ease-in-out duration-300`}>
      <button
        onClick={() => {
          setLinkTwoHover(!linkTwoHover);
        }}
        className={`mr-10 w-[85%] text-start relative ${
          linkTwoHover ? "text-accent" : "text-secondary"
        } font-bold `}
      >
        EYES
        <FontAwesomeIcon
          className="w-3 absolute right-0"
          icon={faChevronDown}
        ></FontAwesomeIcon>
      </button>
      <div
        className={`mobile-menu-hover ${
          linkTwoHover ? "grid-transition text-start px-4 py-4 " : ""
        } text-black `}
      >
        <div>
          <hr className=" w-full text-white my-2" />
          <Link to={`/product-category/eyes`} className=" hover:text-accent">
            All products
          </Link>
          <hr className=" w-full text-white my-2" />
          <Link to={`/product-category/eye liner`} className=" hover:text-accent">
            Eye Liner
          </Link>

          <hr className=" w-full text-white my-2" />
          <Link to={`/product-category/eye pencil`} className=" hover:text-accent">
            Eye Pencil
          </Link>

          <hr className=" w-full text-white my-2" />
          <Link to={`/product-category/mascara`} className=" hover:text-accent">
            Mascara
          </Link>
          <hr className=" w-full text-white my-2" />
          <Link to={`/product-category/eye shadow`} className=" hover:text-accent">
            Eye Shadow
          </Link>
          <hr className=" w-full text-white my-2" />
          <Link to={`/product-category/eye primer`} className=" hover:text-accent">
            Eye Primer
          </Link>
          <hr className=" w-full text-white my-2" />
          <Link to={`/product-category/pomade`} className=" hover:text-accent">
            Pomade
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MobileEyes;
