import "./Home.css";
import Banner from "../../Components/Banner/Banner";
import Featured from "../../Components/Featured/Featured";
import HomeBanner from "../../Components/HomeBanner/HomeBanner";
import Policy from "../../Components/Policy/Policy";
import MostFavorite from "../../Components/MostFavorite/MostFavorite";
import SummerMakeup from "../../Components/SummerMakeup/SummerMakeup";
import { useContext } from "react";
import { ThemeContext } from "../../Contexts/ThemeContext";
import InstaShop from "../../Components/InstaShop";
import Stores from "../../Components/Stores";
import { Helmet } from "react-helmet-async";
import ogIcon from '../../Images/ogIcon.ico'
import TopMakeupBrand from "../../Components/TopMakeupBrand/TopMakeupBrand";
import HomeProductImage from "../../Components/HomeProductImage/HomeProductImage";

const Home = () => {
  const { loading } = useContext(ThemeContext);

  // Parse the date strings into Date objects
  // const date1 = new Date(date1String);
  // const date2 = new Date(date2String);
  // console.log(date1>date2)

  return (
    <div className="home">
       <Helmet>
          <title>Flormar Bangladesh- Top Makeup Brands In Bangladesh</title>
          <meta
            name="description"
            content="Flormar Bangladesh, Top Makeup Brands In Bangladesh! Explore The Best Cosmetics At The Best Price For Face, Eyes, Lips, Nails And Accessories. Discover Makeup That Enhances Your Natural Beauty."
          />
          <link
              rel="canonical"
              href="https://flormarbd.com"
            />
            <meta property="og:title" content="Flormar Bangladesh" />
            <meta property="og:description" content="Welcome to Flormar Bangladesh, your beauty destination! Explore cosmetics for face, eyes, lips, nails, and accessories. Discover makeup that enhances your natural beauty." />
            <meta property="og:image" content={ogIcon}/>
            <meta property="og:url" content="https://flormarbd.com"/>
            <meta property="og:type" content="website" />
            <meta name="robots" content="INDEX, FOLLOW" />
        </Helmet>
      <div>
        {/* <HomeBanner></HomeBanner> */}
        <Banner></Banner>
        <Featured></Featured>
        <TopMakeupBrand></TopMakeupBrand>
        <SummerMakeup></SummerMakeup>
        <HomeProductImage></HomeProductImage>
        <MostFavorite></MostFavorite>
        <InstaShop></InstaShop>
        <Policy></Policy>
        <Stores></Stores>
      </div>
    </div>
  );
};

export default Home;
