import Product from "../Shared/Product";
import { ThemeContext } from "../../Contexts/ThemeContext";
import { useContext } from "react";
import { Link } from "react-router-dom";

const TopMakeupBrand = () => {
  const { topMakeup } = useContext(ThemeContext);

  return (
    <div className="featured-collections flex flex-col items-center px-[10px] 2xl:px-[18%] lg:px-[13%] mx-auto mt-10 bg-[#fceef2]">
      <h1 className="text-center text-xl text-accent font-bold pt-[50px] sm:text-2xl">
        TOP BRAND MAKEUP
      </h1>
      <div className="w-[200px] mx-auto h-1 bg-accent sm:my-6 mb-10"></div>
      <div className="grid grid grid-cols-2 lg:pb-20 lg:grid-cols-4 sm:gap-6 featuredCollection">
        {topMakeup?.slice(0,8)?.map((product) => (
          <Product product={product}></Product>
        ))}
      </div>
      <Link to="/product-category/lipstick" className=" text-secondary hover:text-accent font-bold mb-10 underline">
        View all products
      </Link>
    </div>
  );
};

export default TopMakeupBrand;
