// EditSlider.js
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";

const EditSlider = () => {
  const { id } = useParams();
  const [slider, setSlider] = useState({});
  const [pcImage, setPcImage] = useState(null);
  const [mobileImage, setMobileImage] = useState(null);
  const [order, setOrder] = useState(1);
  const [status, setStatus] = useState("draft");
  const [link, setLink] = useState("");
  const [name, setName] = useState("");

  useEffect(() => {
    fetch(
      `https://flormar-web-server-nayem55-flormar-bd.vercel.app/get-slider/${id}`
    )
      .then((res) => res.json())
      .then((data) => {
        setSlider(data);
        setOrder(data.order);
        setStatus(data.status);
        setLink(data.link);
        setName(data.name);
        setMobileImage(data.mobileImage);
        setPcImage(data.pcImage);
      })
      .catch((err) => console.error(err));
  }, [id]);
  console.log(pcImage)
  const handleUpdate = (e) => {
    e.preventDefault();

    const formData = {
      name,
      link,
      status,
      order,
      pcImage: pcImage || "",
      mobileImage: mobileImage || "",
    };

    console.log(formData);

    fetch(
      `https://flormar-web-server-nayem55-flormar-bd.vercel.app/update-slider/${id}`,
      {
        method: "put",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify(formData),
      }
    )
      .then((res) => res.json())
      .then((data) => toast.success("Slider Updated Successfully"))
      .catch((err) => console.error(err));
  };

  return (
    <div className="max-w-lg mx-auto p-4 my-[100px]">
      <h1 className="text-2xl font-bold mb-4">Edit Slider</h1>
      <form onSubmit={handleUpdate} className="space-y-4">
        <input
          type="name"
          onChange={(e) => setName(e.target.value)}
          className="w-full border border-gray-300 p-2 rounded"
          value={name}
          placeholder="Slider Name"
        />
        {/* <input 
          type="file" 
          onChange={(e) => setPcImage(e.target.files[0])} 
          className="w-full border border-gray-300 p-2 rounded" 
        />
        <input 
          type="file" 
          onChange={(e) => setMobileImage(e.target.files[0])} 
          className="w-full border border-gray-300 p-2 rounded" 
        /> */}
        <input
          type="text"
          onChange={(e) => setPcImage(e.target.value)}
          className="w-full border border-gray-300 p-2 rounded"
          placeholder="Desktop image link"
          value={pcImage}
          required
        />
        <input
          type="text"
          onChange={(e) => setMobileImage(e.target.value)}
          className="w-full border border-gray-300 p-2 rounded"
          placeholder="Mobile image link"
          value={mobileImage}
          required
        />
        <input
          type="number"
          placeholder="Order"
          value={order}
          onChange={(e) => setOrder(e.target.value)}
          className="w-full border border-gray-300 p-2 rounded"
          required
        />
        <select
          value={status}
          onChange={(e) => setStatus(e.target.value)}
          className="w-full border border-gray-300 p-2 rounded"
          required
        >
          <option value="draft">Draft</option>
          <option value="published">Published</option>
        </select>
        <input
          type="text"
          placeholder="Link"
          value={link}
          onChange={(e) => setLink(e.target.value)}
          className="w-full border border-gray-300 p-2 rounded"
          required
        />
        <button
          type="submit"
          className="w-full bg-accent hover:bg-black ease-in-out duration-200 text-white p-2 rounded"
        >
          Update Slider
        </button>
      </form>
    </div>
  );
};

export default EditSlider;
