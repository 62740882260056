import { useEffect, useState } from "react";
import { getStoredCart } from "../utilities/CartDb";

const useCart = (products) => {
  const [cart, setCart] = useState([]);

  // useEffect(() => {
  //    const storedCart = getStoredCart()
  //    const savedCart = []
  //    for (let id in storedCart) {
  //       const addedProduct = products?.find(product => product._id == id)
  //       if (addedProduct) {
  //          addedProduct.quantity = storedCart[id]
  //          savedCart.push(addedProduct)
  //       }
  //    }
  //    setCart(savedCart)
  // }, [products])

  useEffect(() => {
    const storedCart = getStoredCart();
    const savedCart = [];
    const selectedProductIds = Object.keys(storedCart); //? retums as array

    fetch(
      "https://flormar-web-server-nayem55-flormar-bd.vercel.app/cartProductsByIds",
      {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify(selectedProductIds),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        for (let id in storedCart) {
          const addedProduct = data.find((product) => product._id === id);
          if (addedProduct) {
            addedProduct.quantity = storedCart[id];
            savedCart.push(addedProduct);
          }
        }
        setCart(savedCart);
      });
  }, []);

  return [cart, setCart];
};

export default useCart;
