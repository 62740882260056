import { Link } from "react-router-dom";
import useScroll from "../../Hooks/useScroll";
import PopCart from "../PopCart/PopCart";
import logo from "../../Images/F-logo.png";
import FaceGroup from "../NavCategories/FaceGroup";
import Eyes from "../NavCategories/Eyes";
import Lips from "../NavCategories/Lips";
import Nails from "../NavCategories/Nails";
import Accessories from "../NavCategories/Accessories";

const LargeCategory = ({ popCart, handlePopCart, setCategory }) => {
  const [scrollPosition] = useScroll();

  const openPdfLink = () => {
    const pdfUrl =
      "https://pdfhost.io/v/KB~v4P1ZS_Global_Merchandising_Catalogue19082024bd";
    window.open(pdfUrl, "_blank"); // Opens the URL in a new tab/window
  };

  return (
    <div
      className={`nav-menu relative hidden lg:flex transition-all ease-in-out duration-300 text-center min-h-[65px] text-white  ${
        scrollPosition > 80
          ? "nav-menu-scrolled border border-0 border-b-2"
          : "p-0"
      }`}
    >
      <PopCart handlePopCart={handlePopCart} popCart={popCart}></PopCart>

      {/*----------- Nav Scrolled Logo  -----------------*/}

      {scrollPosition > 180 ? (
        <Link>
          <img
            className="ms-10 short-logo absolute top-[6px] left-0"
            src={logo}
            alt=""
          />
        </Link>
      ) : (
        ""
      )}

      {/*------------------------------------- All Navigation Category  ------------------------------------*/}

      <div className="all-menu">
        <FaceGroup />
        <Eyes />
        <Lips />
        <Nails />
        <Accessories />

        {/*------------------ new arrivals  ----------------*/}
        <Link
          className={`mr-10 block font-bold ${
            scrollPosition > 80
              ? "hover:text-accent text-secondary"
              : "text-secondary hover:text-accent"
          }`}
          to={`/newarrivals`}
        >
          NEW ARRIVALS
        </Link>

        {/*----------------- Vegan  --------------------*/}
        <Link
          className={`mr-10 block font-bold ${
            scrollPosition > 80
              ? "hover:text-accent text-secondary"
              : "text-secondary hover:text-accent"
          }`}
          to={`/product-category/vegan`}
        >
          VEGAN
        </Link>
        {/*----------------- Combos  --------------------*/}
        {/* <Link
          className={`mr-10 block font-bold ${
            scrollPosition > 80
              ? "hover:text-accent text-secondary"
              : "text-secondary hover:text-accent"
          }`}
          to={`/combo`}
        >
          COMBOS
        </Link> */}

        {/*-------------- Blogs ----------------*/}
        <Link
          to="/blogs"
          className={`mr-10 block font-bold ${
            scrollPosition > 80
              ? "hover:text-accent text-secondary"
              : "text-secondary hover:text-accent"
          }`}
        >
          BLOGS
        </Link>
        <Link
          onClick={openPdfLink}
          className={`mr-10 block font-bold ${
            scrollPosition > 80
              ? "hover:text-accent text-secondary"
              : "text-secondary hover:text-accent"
          }`}
        >
          CATALOGUES
        </Link>
        <Link
        to="/wholesale"
          className={`mr-10 block font-bold ${
            scrollPosition > 80
              ? "hover:text-accent text-secondary"
              : "text-secondary hover:text-accent"
          }`}
        >
          WHOLESALE
        </Link>
        <Link
        to="/franchise"
          className={`mr-10 block font-bold ${
            scrollPosition > 80
              ? "hover:text-accent text-secondary"
              : "text-secondary hover:text-accent"
          }`}
        >
          FRANCHISE
        </Link>
      </div>
    </div>
  );
};

export default LargeCategory;
