import React, { useState } from "react";
import img from "../Images/wholesale.jpg";

const Franchise = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [number, setNumber] = useState("");
  const [company, setCompany] = useState("");
  const [occupation, setOccupation] = useState("");
  const [address, setAddress] = useState("");
  const [desc, setDesc] = useState("");
  const [date, setDate] = useState("");
  const [position, setPosition] = useState("");

  const recipientEmail = "bangladeshflormar@gmail.com";
  const emailSubject = "";
  const emailBody = "";

  const mailtoLink = `mailto:${recipientEmail}?subject=${emailSubject}&body=${emailBody}`;

  return (
    <div className="sm:p-16 flex gap-10 flex-col lg:flex-row">
      <div className="p-4 sm:p-10 flex flex-col gap-4 w-[100%] lg:w-[50%]">
        <div>
          <p>Your name</p>
          <input
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="border border-secondary px-4 py-2 w-[100%] border-opacity-30 rounded"
            type="text"
            placeholder="Your name"
          />
        </div>
        <div>
          <p>Subject</p>
          <input
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            className="border border-secondary px-4 py-2 w-[100%] border-opacity-30 rounded"
            type="text"
            placeholder="Write your subject"
          />
        </div>
        <div>
          <p>Your address</p>
          <input
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            className="border border-secondary px-4 py-2 w-[100%] border-opacity-30 rounded"
            type="email"
            placeholder="Your address"
          />
        </div>
        <div>
          <p>Contact number</p>
          <input
            value={number}
            onChange={(e) => setNumber(e.target.value)}
            className="border border-secondary px-4 py-2 w-[100%] border-opacity-30 rounded"
            type="number"
            placeholder="Your contact number"
          />
        </div>
        <div>
          <p>Your email</p>
          <input
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="border border-secondary px-4 py-2 w-[100%] border-opacity-30 rounded"
            type="email"
            placeholder="Your email"
          />
        </div>
        <div>
          <p>Company name </p>
          <input
            value={company}
            onChange={(e) => setCompany(e.target.value)}
            className="border border-secondary px-4 py-2 w-[100%] border-opacity-30 rounded"
            type="text"
            placeholder="Company name"
          />
        </div>
        <div>
          <p>Occupation Field</p>
          <input
            value={occupation}
            onChange={(e) => setOccupation(e.target.value)}
            className="border border-secondary px-4 py-2 w-[100%] border-opacity-30 rounded"
            type="text"
            placeholder="Occupation Field"
          />
        </div>
        <div>
          <p>Date</p>
          <input
            value={date}
            onChange={(e) => setDate(e.target.value)}
            className="border border-secondary px-4 py-2 w-[100%] border-opacity-30 rounded"
            type="date"
          />
        </div>
        <div>
          <p>Position</p>
          <input
            value={position}
            onChange={(e) => setPosition(e.target.value)}
            className="border border-secondary px-4 py-2 w-[100%] border-opacity-30 rounded"
            type="text"
            placeholder="Write your position"
          />
        </div>
        <div>
          <p>Description</p>
          <textarea
            rows={6}
            value={desc}
            onChange={(e) => setDesc(e.target.value)}
            className="border border-secondary px-4 py-2 w-[100%] border-opacity-30 rounded"
            type="text"
            placeholder="Describe duties, number of employees supervised and responsibilities"
          />
        </div>
        <a
          className="bg-secondary text-center px-10 py-3 text-primary rounded hover:bg-accent ease-in-out duration-200"
          href={mailtoLink}
        >
          Submit
        </a>
      </div>
      <div className="w-[100%] lg:w-[50%] mt-10">
        <img src={img} alt="" />
        <p className="text-center mt-10 font-bold text-2xl">
          Grow Your Business with us
        </p>
        <p className="text-center mb-10">
          This opportunity is very precious for all.
        </p>
      </div>
    </div>
  );
};

export default Franchise;
