import React from "react";
import img from "../Images/location.png";

const MallsForClearanceSale = () => {
  return (
    <div>
      <div className="bg-[#F9F9F9] p-6 sm:p-16">
        <p className="text-2xl sm:text-4xl font bold text-center">
          Outlets For Available Clearance Sale 
        </p>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 2xl:grid-cols-2 px-4 sm:px-16 py-10 gap-6 ">
        <a target="_blank" rel="noreferrer" href="https://www.google.com/maps/search/Flormar+near+Tajmahal+Road,+Dhaka/@23.7476494,90.3724548,15z/data=!3m1!4b1?entry=ttu">
          <div className="bg-[#FCEEF2] min-h-[300px] p-10 flex flex-col justify-center items-center rounded shadow-xl hover:shadow-lg ease-in-out duration-200">
            <p className="text-xl text-accent font-bold text-center">
              TAJMAHAL ROAD, MOHAMMADPUR
            </p>
            <p className="text-[#AA507A] font-bold text-center">
              {" "}
              Shop# 15 , Mohammadpur Girls School Market , Tajmahal Road ,
              Dhaka-1207.
            </p>
            <p className="mt-6 font-bold"> 01404-403864</p>
            <img className="mt-6" src={img} alt="" />
          </div>
        </a>
        <a target="_blank" rel="noreferrer" href="https://www.google.com/maps/place/Flormar+(+Adabor+)/@23.7693284,90.3533158,17z/data=!3m2!4b1!5s0x3755c0a22cf2fc31:0x2af35ea46df7de4d!4m6!3m5!1s0x3755c1180bba23b7:0x668cf92a1cb09900!8m2!3d23.7693236!4d90.3581867!16s%2Fg%2F11slg0jyh8?entry=ttu">
          <div className="bg-[#FCEEF2] min-h-[300px] p-10 flex flex-col justify-center items-center rounded shadow-xl hover:shadow-lg ease-in-out duration-200">
            <p className="text-xl text-accent font-bold text-center">
              ADABAR, MOHAMMADPUR
            </p>
            <p className="text-[#AA507A] font-bold text-center">
              {" "}
              Shop# 28 , Shahbuddin Plaza , Adabor , Mohammadpur , Dhaka-1207.
            </p>
            <p className="mt-6 font-bold"> 01313-995454</p>
            <img className="mt-6" src={img} alt="" />
          </div>
        </a>
        <a target="_blank" rel="noreferrer" href="https://www.google.com/maps/place/Flormar+CTG+Mono+Outlet/@22.3617542,91.8116471,17z/data=!3m1!4b1!4m6!3m5!1s0x30acd9cc359755c1:0x4bd857ab4b6cfa87!8m2!3d22.3617493!4d91.816518!16s%2Fg%2F11k2b2qwh1?entry=ttu">
          <div className="bg-[#FCEEF2] min-h-[300px] p-10 flex flex-col justify-center items-center rounded shadow-xl hover:shadow-lg ease-in-out duration-200">
            <p className="text-xl text-accent font-bold text-center">
              CHATTOGRAM
            </p>
            <p className="text-[#AA507A] font-bold text-center">
              {" "}
              Shop# 204 , 2nd Floor , Finlay Square , 2nd Gate , Chattogram-4000
            </p>
            <p className="mt-6 font-bold"> 01928-005000</p>
            <img className="mt-6" src={img} alt="" />
          </div>
        </a>
        <a target="_blank" rel="noreferrer" href="https://www.google.com/maps/place/Flormar+(Mirpur+12)/@23.8279667,90.361291,17z/data=!3m1!4b1!4m6!3m5!1s0x3755c1c4139c2f95:0xd7e34cab407268ac!8m2!3d23.8279618!4d90.3638659!16s%2Fg%2F11vqll_sm8?entry=ttu">
          <div className="bg-[#FCEEF2] min-h-[300px] p-10 flex flex-col justify-center items-center rounded shadow-xl hover:shadow-lg ease-in-out duration-200">
            <p className="text-xl text-accent font-bold text-center">MIRPUR</p>
            <p className="text-[#AA507A] font-bold text-center">
              {" "}
              Shop# 05 , Ground Floor , Noor Islam Mollah Avenue , 5 No Sujat
              Nagar , Pallabi , Mirpur-12
            </p>
            <p className="mt-6 font-bold">01404403332</p>
            <img className="mt-6" src={img} alt="" />
          </div>
        </a>
      </div>
    </div>
  );
};

export default MallsForClearanceSale;
