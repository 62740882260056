import React, { useContext } from "react";
import "./SearchedProducts.css";
import { Link, useNavigate } from "react-router-dom";
import { ThemeContext } from "../../Contexts/ThemeContext";

const SearchedProducts = ({
  searchedProducts,
  highPriorityProducts,
  searchedProductCount,
  focus,
  handleSearch,
}) => {
  const navigate = useNavigate();
  const { searchText } = useContext(ThemeContext);

  let searchedArray = [];

  if (highPriorityProducts.length > 0) {
    searchedArray = highPriorityProducts;
  } else {
    searchedArray = searchedProducts;
  }

  return (
    <div className={`searchedProducts  ${focus ? "block" : "hidden"}`}>
      <p className="text-xs font-bold">POPULAR PRODUCTS</p>
      <hr className="my-2" />
      <div className="searchedProduct cursor-pointer">
        {searchedArray?.slice(0, 3)?.map((product) => (
          <div
            onClick={() => {
              navigate(`/product/${product?.slug}`);
              // handleSearch(false);
            }}
            className="flex flex-col items-center"
          >
            <img src={product?.images[0]?.src} alt="" />
            <div className="searchedProductDetails">
              <p className="limit-lines" title={product?.name}>
                {product?.name}
              </p>
              <p className="text-accent font-bold">
                TK.{" "}
                {JSON.stringify(product?.on_sale) === "true"
                  ? product?.sale_price
                  : product?.regular_price}{" "}
              </p>
            </div>
          </div>
        ))}
      </div>
      {searchedProducts?.length > 0 && (
        <div>
          <hr className="my-2" />
          <Link
            // onClick={() => {
            //   handleSearch(false);
            // }}
            to={`/search/${searchText}`}
          >
            <p className="text-center text-secondary text-opacity-50 mt-2 text-xs font-bold hover:text-accent pointer">
              SEE ALL RESULTS ( {searchedProductCount} )
            </p>
          </Link>
        </div>
      )}
    </div>
  );
};

export default SearchedProducts;
