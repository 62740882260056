import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
// import MessengerCustomerChat from "react-messenger-customer-chat";
import { HelmetProvider } from "react-helmet-async";
// import { CustomChat, FacebookProvider } from "react-facebook";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    {/* <MessengerCustomerChat pageId="237294359776495"/> */}
    {/* <FacebookProvider chatSupport>
      <CustomChat pageId="237294359776495" minimized={true} />
    </FacebookProvider> */}
    <HelmetProvider>
      <App />
    </HelmetProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
