import React from "react";
import "./Policy.css";
import img1 from "../../Images/delivery-truck-q2799n3iypgh9l68ln7z8ejstamgh49cdexz6jvl0g.png";
import img2 from "../../Images/delivery-status-q2799n3iypgh9l68ln7z8ejstamgh49cdexz6jvl0g.png";
import img3 from "../../Images/payment-security-q2799o1d5jhrl74vg5mlswb9eohtotd2pjlgntu6u8.png";
import img4 from "../../Images/helpline-q2799o1d5jhrl74vg5mlswb9eohtotd2pjlgntu6u8.png";
import { useNavigate } from "react-router-dom";

const Policy = () => {
  const navigate = useNavigate();
  return (
    <div className="grid justify-center gap-10 bg-[#F5F5F5] p-10 2xl:px-[20%] lg:px-[15%] mx-auto grid-cols-2 justify-between lg:grid-cols-4">
      <div className="flex flex-col items-center">
        <img src={img1} alt="" />
        <p className="text-xl font-bold text-center mt-6 sm:mt-10">
          Fast & Free Delivery
        </p>
        <p className="text-center w-[150px] sm:w-[150px] sm:w-[200px] text-secondary text-opacity-70">
          Get Free Delivery for Tk. 1000+ within 2-3 Business Days
        </p>
      </div>
      <div
        onClick={() => navigate("/refund")}
        className="flex flex-col items-center cursor-pointer"
      >
        <img src={img2} alt="" />
        <p className="text-xl font-bold text-center mt-6 sm:mt-10">
          Easy Return Policy
        </p>
        <p className="text-center w-[150px] sm:w-[200px] text-secondary text-opacity-70">
          Easily Return your order within 3 Business days
        </p>
      </div>
      <div className="flex flex-col items-center">
        <img src={img3} alt="" />
        <p className="text-xl font-bold text-center mt-6 sm:mt-10">
          Secure Online Payment
        </p>
        <p className="text-center w-[150px] sm:w-[200px] text-secondary text-opacity-70">
          Pay securely in Flormar with Online & Mobile Payments
        </p>
      </div>
      <div
        onClick={() => {
          window.location.href = `tel:${+8801969906700}`;
        }}
        className="flex flex-col items-center cursor-pointer"
      >
        <img src={img4} alt="" />
        <p className="text-xl font-bold text-center mt-6 sm:mt-10">
          Customer Helpline
        </p>
        <p className="text-center text-accent text-xl font-bold">
          +88 01969 906 700
        </p>
      </div>
    </div>
  );
};

export default Policy;
