import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

const MobileLips = () => {
    const [linkThreeHover, setLinkThreeHover] = useState(false);
    return (
        <div
        className={`mobile-perfume transition-all ease-in-out duration-300`}
    >
      <button
        onClick={() => {
          setLinkThreeHover(!linkThreeHover)
        }}
        className={`mr-10 w-[85%] text-start relative ${linkThreeHover?"text-accent":"text-secondary"} font-bold `}
      >
        Lips
        <FontAwesomeIcon
          className="w-3 absolute right-0"
          icon={faChevronDown}
        ></FontAwesomeIcon>
      </button>

      <div className={`mobile-menu-hover ${
            linkThreeHover ? "grid-transition text-start px-4 py-4 " : ""
          } text-black `}>

      <div>
      <hr className=" w-full text-white my-2" />
          <Link to={`/product-category/lips`} className=" hover:text-accent">
            All products
          </Link>
      <hr className=" w-full text-white my-2" />
          <Link to={`/product-category/lip care`} className=" hover:text-accent">
            Lip Care
          </Link>
        <hr className=" w-full text-white my-2" />
        <Link to={`/product-category/lip gloss`} className=" hover:text-accent">
          Lip Gloss
        </Link>
        <hr className=" w-full text-white my-2" />
        <Link to={`/product-category/lip pencil`} className=" hover:text-accent">
          Lip Pencil
        </Link>
        <hr className=" w-full text-white my-2" />
        <Link to={`/product-category/lipstick`} className=" hover:text-accent">
          Lip Stick
        </Link>
        </div>
      </div>
    </div>
    );
};

export default MobileLips;