import React from "react";
import { Link } from "react-router-dom";
import img1 from "../Images/flormar-extra-bp.png";
import img2 from "../Images/store.webp";

const Stores = () => {
  return (
    <div className="flex flex-col sm:flex-row">
      <div className="sm:w-[50%] p-10 bg-[#FCEEF2] flex flex-col items-center">
        <img src={img1} className="mb-10 mx-auto" alt="" />
        <p className="text-accent text-center font-bold text-2xl">
          The Colorful World of Flormar Extra!
        </p>
        <p className="text-secondary text-opacity-70 text-center mb-6">
          Sign up now to get the priviledges
        </p>
        <Link
          to="/customerDashboard"
          className="px-6 py-2 bg-accent text-primary rounded hover:bg-secondary ease-in-out duration-200 "
        >
          SIGN UP
        </Link>
      </div>
      <div className="sm:w-[50%] p-10 bg-[#F9DEE6] flex flex-col items-center sm:items-start">
        <p className="text-secondary font-bold text-3xl mb-6">Stores</p>
        <Link
          to="/malls"
          className="px-6 py-3 bg-accent text-primary rounded hover:bg-secondary ease-in-out duration-200   "
        >
          FIND NEAREST STORE
        </Link>
        <img src={img2} className="mt-10 mx-auto" alt="" />
      </div>
    </div>
  );
};

export default Stores;
