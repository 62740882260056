import React, { useContext, useEffect } from "react";
import "./MobileCategory.css";
import { Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import MobileBestSeller from "./MobileCatefories/MobileAccessories";
import { ThemeContext } from "../../Contexts/ThemeContext";
import MobileFace from "./MobileCatefories/MobileFace";
import MobileEyes from "./MobileCatefories/MobileEyes";
import MobileLips from "./MobileCatefories/MobileLips";
import MobileNails from "./MobileCatefories/MobileNails";

const MobileCategory = ({ menu, handleMenu }) => {
  const { setCategory } = useContext(ThemeContext);
  const location = useLocation();

  useEffect(() => {
    handleMenu(false);
  }, [location.pathname]);

  const openPdfLink = () => {
    const pdfUrl =
      "https://pdfhost.io/v/KB~v4P1ZS_Global_Merchandising_Catalogue19082024bd";
    window.open(pdfUrl, "_blank"); // Opens the URL in a new tab/window
  };

  return (
    <div
      className={`mobile-category-container ${
        menu ? "" : "mobile-category-container-left"
      }`}
    >
      {/*------------------------------------- All Navigation Category  ------------------------------------*/}

      <FontAwesomeIcon
        icon={faXmark}
        onClick={() => handleMenu(false)}
        className={` block ml-auto w-[24px] h-[24px] m-4 text-accent`}
      />
      <div className="mobile-category">
        <MobileFace />
        <br />
        <MobileEyes />
        <br />
        <MobileLips />
        <br />
        <MobileNails />
        <br />
        <MobileBestSeller />
        <br />

        {/*------------------ new arrivals  ----------------*/}
        <Link
          className={`mr-10 block font-bold `}
          to={`/newarrivals`}
        >
          NEW ARRIVALS
        </Link>
        <br />
        {/*----------------- Vegan  --------------------*/}
        <Link className={`mr-10 block font-bold `} to={`/product-category/vegan`}>
          VEGAN
        </Link>
        <br />
        {/*----------------- Combos  --------------------*/}
        {/* <Link className={`mr-10 block font-bold `} to={`/combo`}>
          COMBOS
        </Link>
        <br /> */}

        {/*-------------- Blogs ----------------*/}
        <Link to="/blogs" className={`mr-10 block font-bold `}>BLOGS</Link>
        <br />
        <Link to="/shop" className={`mr-10 block font-bold `}>SHOP</Link>
        <br />
        <Link to="/campaign" className={`mr-10 block font-bold `}>CAMPAIGN</Link>
        <br />
        <Link to="/malls" className={`mr-10 block font-bold `}>MALLS</Link>
        <br />
        <Link to="/trackOrder" className={`mr-10 block font-bold `}>TRACK ORDER</Link>
        <br />

        <Link onClick={openPdfLink} className={`mr-10 block font-bold `}>CATALOGUES</Link>
        <br />

        <Link to="/wholesale" className={`mr-10 block font-bold `}>WHOLESALE</Link>
        <br />

        <Link to="/franchise" className={`mr-10 block font-bold `}>FRANCHISE</Link>
        <br />
        <a href="https://luvit.com.bd" target="__blank"><img className="w-[80px]" src="https://luvit.com.bd/wp-content/uploads/2022/11/Luvit-final-Logo.svg" alt=""/></a>
      </div>
    </div>
  );
};

export default MobileCategory;
