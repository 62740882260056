import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "./Featured.css";
import { useContext } from "react";
import { ThemeContext } from "../../Contexts/ThemeContext";
import { Link } from "react-router-dom";
import banner1 from "../../Images/face.webp";
import banner2 from "../../Images/eyes.webp";
import banner3 from "../../Images/lip.jpg";
import banner4 from "../../Images/nails.webp";
import banner5 from "../../Images/website-category-banner-accessories.webp";

const Featured = () => {
  return (
    <div className=" 2xl:w-[80%] lg:w-[85%] mx-auto featured">
      <div className="flex flex-col items-center w-[100%]">
        <Link to="/product-category/face">
          <img className="rounded-2xl" src={banner1} alt="face products" />
        </Link>
        <Link to="/product-category/face" className="text-center">
          <p className="text-secondary mt-6 font-bold">FACE</p>
        </Link>
      </div>

      <div className="flex flex-col items-center w-[100%]">
        <Link to="/product-category/eyes">
          <img className="rounded-2xl" src={banner2} alt="best eye makeup products" />
        </Link>
        <Link to="/product-category/eyes" className="text-center">
          <p className="text-secondary mt-6 font-bold">EYES</p>
        </Link>
      </div>

      <div className="flex flex-col items-center w-[100%]">
        <Link to={`/product-category/lips`}>
          <img className="rounded-2xl w-[274px] h-[180px]" src={banner3} alt="lipstick" />
        </Link>
        <Link to={`/product-category/lips`} className="text-center">
          <p className="text-secondary mt-6 font-bold">LIPS</p>
        </Link>
      </div>

      <div className="flex flex-col items-center w-[100%]">
        <Link to={`/product-category/nails`}>
          <img className="rounded-2xl" src={banner4} alt="nail makeup products" />
        </Link>
        <Link to={`/product-category/nails`} className="text-center">
          <p className="text-secondary mt-6 font-bold">NAILS</p>
        </Link>{" "}
      </div>

      <div className="flex flex-col items-center w-[100%]">
        <Link to="/product-category/accessories">
          <img className="rounded-2xl" src={banner5} alt="makeup accessories" />
        </Link>
        <Link to="/product-category/accessories" className="text-center">
          <p className="text-secondary mt-6 font-bold">ACCESSORIES</p>
        </Link>
      </div>
    </div>
  );
};

export default Featured;
