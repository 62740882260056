// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

const firebaseConfig = {
  apiKey: "AIzaSyBt_PDhquBvXgt38DeaLbBgzK5b9ghbCRI",
  authDomain: "flormar-30709.firebaseapp.com",
  projectId: "flormar-30709",
  storageBucket: "flormar-30709.appspot.com",
  messagingSenderId: "939232580508",
  appId: "1:939232580508:web:761ef3e4a908fa79196e59",
};



// const firebaseConfig = {
//   apiKey: "AIzaSyCnYblrw1cy27oMRcHAtBNvenlXGRA2a5c",
//   authDomain: "flormarbd-cd4ac.firebaseapp.com",
//   projectId: "flormarbd-cd4ac",
//   storageBucket: "flormarbd-cd4ac.appspot.com",
//   messagingSenderId: "446935921871",
//   appId: "1:446935921871:web:6a694ec72834bdf81033ba",
//   measurementId: "G-TXBC6Q5EZY"
// };

// const firebaseConfig = {
//   apiKey: "AIzaSyAqv7CNte9_8NQV5iVCE7DmmIeSgZ7n0sU",
//   authDomain: "flormar-auth.firebaseapp.com",
//   projectId: "flormar-auth",
//   storageBucket: "flormar-auth.appspot.com",
//   messagingSenderId: "278755797096",
//   appId: "1:278755797096:web:0924e736b0ad385a68b3dd",
//   measurementId: "G-TZFZ6YQWY3"
// };

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export default auth;

