import { faPenToSquare, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { ThreeDots } from "react-loader-spinner";
import { Link } from "react-router-dom";

const AllUser = () => {
  const [pageCount, setPageCount] = useState(0);
  const [orders, setOrders] = useState([]);
  const [page, setPage] = useState(0);
  const [users, setUsers] = useState([]);
  const [searchedText, setSearchedText] = useState("");
  const [loading, setLoading] = useState(false);
  const [deletedId, setDeletedId] = useState();
  const [editUser, setEditUser] = useState();

  useEffect(() => {
    fetch("https://server.flormarbd.com/userCount")
      .then((res) => res.json())
      .then((data) => {
        const count = data.count;
        const pages = Math.ceil(count / 50);
        setPageCount(pages);
      });
  }, []);

  useEffect(() => {
    setUsers([]);
    setLoading(true);
    fetch(
      `https://server.flormarbd.com/getAllUser?page=${page}`
    )
      .then((res) => res.json())
      .then((data) => {
        setUsers(data);
        setLoading(false);
      });
  }, [page]);

  const handleDelete = (id) => {
    fetch(
      `https://server.flormarbd.com/deleteUser/${id}`,
      {
        method: "delete",
      }
    )
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
      });
    toast.success("User deleted sucessfully");
    const rest = users.filter((user) => user._id !== id);
    setUsers(rest);
  };
  const handleMakeAdmin = (U) => {
    fetch(
      `https://server.flormarbd.com/editUser/${U._id}`,
      {
        method: "put",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({
          role: "admin",
        }),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
      });
    toast.success("User updated sucessfully");
    setUsers(
      users.map((user) => {
        if (user._id === U._id) {
          user.role = "admin";
          return user;
        } else return user;
      })
    );
  };
  const handleRemoveAdmin = (U) => {
    fetch(
      `https://server.flormarbd.com/editUser/${U._id}`,
      {
        method: "put",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({
          role: "customer",
        }),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
      });
    toast.success("User updated sucessfully");
    setUsers(
      users.map((user) => {
        if (user._id === U._id) {
          user.role = "customer";
          return user;
        } else return user;
      })
    );
  };

  const handleSearch = () => {
    setUsers([]);
    setLoading(true);
    fetch(
      `https://server.flormarbd.com/searchUser/${searchedText}`
    )
      .then((res) => res.json())
      .then((data) => {
        setUsers(data);
        setLoading(false);
      });
  };

  return (
    <div>
      <div className="flex justify-between mx-12">
        <div className="flex">
          <h1 className="mt-12 mr-12 text-3xl font-bold">Users</h1>
          {/* <Link
            to="/admin/adduser"
            className="px-6 mt-12 mr-6 border flex justify-center items-center border-accent bg-primary text-accent hover:bg-accent hover:text-primary ease-in-out duration-300 "
          >
            Add New
          </Link>
          <Link className="px-6 mt-12 border border-accent flex justify-center items-center bg-primary text-accent hover:bg-accent hover:text-primary ease-in-out duration-300 ">
            Bulk Edit
          </Link> */}
        </div>
        <div>
          <input
            type="text"
            placeholder="Search..."
            className="border border-secondary px-4 py-1 border-opacity-40"
            onChange={(e) => setSearchedText(e.target.value)}
            value={searchedText}
          ></input>
          <button
            onClick={handleSearch}
            className="px-6 mt-12 ml-4 py-1 border border-accent bg-primary text-accent hover:bg-accent hover:text-primary ease-in-out duration-300 "
          >
            Search
          </button>
        </div>
      </div>
      {/*.................. pagination ....................*/}
      {pageCount > 1 && (
        <div className="pages">
          {[...Array(pageCount).keys()].map((index) => (
            <button
              className={page === index ? "selected" : ""}
              onClick={() => setPage(index)}
            >
              {index + 1}
            </button>
          ))}
        </div>
      )}
      {/*.................. pagination ....................*/}
      {users.length < 1 && loading ? (
        <div className="flex justify-center items-center h-[90vh]">
          <ThreeDots
            height="100"
            width="100"
            radius="10"
            color="#f34b7f"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={true}
          />
        </div>
      ) : (
        <div className="overflow-x-auto">
          <table className="product-table">
            {/* head */}
            <thead className="bg-accent text-primary">
              <tr>
                <th></th>
                <th>Name</th>
                <th>Phone</th>
                <th>Email</th>
                <th>Orders</th>
                <th>Role</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {/*................table row............... */}
              {users?.map((user) => {
                return (
                  <tr className="">
                    <th>
                      <label>
                        <input type="checkbox" className="checkbox" />
                      </label>
                    </th>
                    <td>
                      <p className="font-bold">{user?.name || "---"}</p>
                    </td>
                    <td>
                      <p className="font-bold">{user?.phone || "---"}</p>
                    </td>
                    <td>
                      <p className="font-bold">{user?.email || "---"}</p>
                    </td>
                    <td>
                      <Link
                        to={`/admin/user/${
                          user?.phone ? user?.phone : user?.email
                        }`}
                        className="bg-accent hover:bg-secondary ease-in-out duration-200 p-4 text-white"
                      >
                        View
                      </Link>
                    </td>
                    <td>{user?.role}</td>
                    <td>
                      {user.role === "admin" ? (
                        <button
                          onClick={() => handleRemoveAdmin(user)}
                          className="mr-4 bg-[#D0312D] ease-in-out duration-200 hover:bg-secondary text-white rounded px-4 py-1"
                        >
                          Remove admin
                        </button>
                      ) : (
                        <button
                          onClick={() => {
                            setEditUser(user);
                            document
                              .getElementById("confirmaAdmin")
                              .showModal();
                          }}
                          className="mr-4 bg-[#8ed03a] ease-in-out duration-200 hover:bg-secondary text-white rounded px-4 py-1"
                        >
                          Make admin
                        </button>
                      )}

                      <dialog
                        id="confirmaAdmin"
                        className="bg-accent rounded p-10"
                      >
                        <form method="dialog" className="">
                          <button className="btn text-primary btn-sm btn-circle btn-ghost absolute right-2 top-2">
                            ✕
                          </button>
                          <h3 className="font-bold text-lg text-primary">
                            Confirm Update !
                          </h3>
                          <p className="py-4 text-primary">
                            Are you sure you want to make this user an admin?
                          </p>
                          <button
                            onClick={() => handleMakeAdmin(editUser)}
                            className="btn py-3 bg-primary text-accent border-none"
                          >
                            Confirm
                          </button>
                        </form>
                      </dialog>
                      <Link
                        onClick={() => {
                          setDeletedId(user._id);
                          document.getElementById("confirmation").showModal();
                        }}
                      >
                        <FontAwesomeIcon
                          className="text-[#b14444]"
                          icon={faTrash}
                        ></FontAwesomeIcon>
                      </Link>
                      <dialog
                        id="confirmation"
                        className="bg-accent rounded p-10"
                      >
                        <form method="dialog" className="">
                          <button className="btn text-primary btn-sm btn-circle btn-ghost absolute right-2 top-2">
                            ✕
                          </button>
                          <h3 className="font-bold text-lg text-primary">
                            Confirm Delete !
                          </h3>
                          <p className="py-4 text-primary">
                            Are you sure you want to delete this user ?
                          </p>
                          <button
                            onClick={() => handleDelete(deletedId)}
                            className="btn py-3 bg-primary text-accent border-none"
                          >
                            Confirm
                          </button>
                        </form>
                      </dialog>
                    </td>
                  </tr>
                );
              })}
            </tbody>
            {/* foot */}
            <tfoot className="bg-accent text-primary">
              <tr>
                <th></th>
                <th>Name</th>
                <th>Phone</th>
                <th>Email</th>
                <th>Orders</th>
                <th>Role</th>
                <th>Actions</th>
              </tr>
            </tfoot>
          </table>
        </div>
      )}

      {/*.................. pagination ....................*/}
      {pageCount > 1 && (
        <div className="pages mb-[100px]">
          {[...Array(pageCount).keys()].map((index) => (
            <button
              className={page === index ? "selected" : ""}
              onClick={() => setPage(index)}
            >
              {index + 1}
            </button>
          ))}
        </div>
      )}
      {/*.................. pagination ....................*/}
    </div>
  );
};

export default AllUser;

//npm install react react-dom react-modal
