import { useEffect, useState } from "react";

const useProduct = () => {
  const [products, setProducts] = useState([]);
  // const [products1, setProducts1] = useState([]);
  // const [products2, setProducts2] = useState([]);
  // const [products3, setProducts3] = useState([]);
  // const [products4, setProducts4] = useState([]);
  // const [products5, setProducts5] = useState([]);
  const [loading, setLoading] = useState(false);

  // useEffect(() => {
  //   fetch("https://server.flormarbd.com/products1")
  //     .then((res) => res.json())
  //     .then((data) => {
  //       setProducts1(data);
  //     });
  //   fetch("https://server.flormarbd.com/products2")
  //     .then((res) => res.json())
  //     .then((data) => {
  //       setProducts2(data);
  //     });
  //   fetch("https://server.flormarbd.com/products3")
  //     .then((res) => res.json())
  //     .then((data) => {
  //       setProducts3(data);
  //     });
  //   fetch("https://server.flormarbd.com/products4")
  //     .then((res) => res.json())
  //     .then((data) => {
  //       setProducts4(data);
  //     });
  //   fetch("https://server.flormarbd.com/products5")
  //     .then((res) => res.json())
  //     .then((data) => {
  //       setProducts5(data);
  //     });
  // }, []);
  // useEffect(() => {
  //   setProducts([...products1, ...products2, ...products3, ...products4, ...products5]);
  //   if (products1.length > 0 && products2.length > 0) {
  //     setLoading(false);
  //   }
  // }, [products1, products2, products3, products4,products5]);
  return [products, loading];
};
export default useProduct;
